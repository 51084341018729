import Asset from '@/models/Asset'
import Inspection from '@/models/Inspection'
import {asset as backend, common as commonBackend} from '@/api'
import Paginator from '@/models/Paginator'
import Vue from "vue";

let asset = {

  namespaced: true,

  state: {
    assets: new Paginator,
    asset: new Asset,
    assetTypeList: [],
    latest_answers: [],
    inspectionHistory: {
      data: []
    },
    assetHistory: {
      data: []
    },
    sensors: [],
    overview: {
      latestInspection: new Inspection
    },
    transactionHistory: [],
    availableSubAssets: [],
    selectedSubAssets: [],
    assetRegisterAssets: [],
  },

  mutations: {
    setInspectionHistory: (state, payload) => state.inspectionHistory = payload,
    setAssetHistory: (state, payload) => state.assetHistory = payload,
    setTransactionHistory: (state, payload) => state.transactionHistory = payload,
    setSensors: (state, payload) => state.sensors = payload,
    setAssets: (state, payload) => state.assets = payload,
    setAsset: (state, payload) => {
      state.asset = payload
      if (payload.meta_data instanceof Array) {
        state.asset.meta_data = {}
      }
    },
    // spread operator to make a copy of the array
    setSubAssets: (state, payload) => state.asset.subAssets = [...payload],
    removeSubAsset: (state, payload) => {
      let index = state.selectedSubAssets.indexOf(payload)
      state.selectedSubAssets.splice(index, 1)
    },
    setAvailableSubAssets: (state, payload) => {
      if(payload.data !== null){
        state.availableSubAssets = payload
      } else {
        state.availableSubAssets.data = []
      }
    },
    setSelectedSubAssets: (state, payload) => state.selectedSubAssets = payload,
    barcode: (state, payload) => state.asset.barcode_id = payload,
    zone: (state, payload) => state.asset.zone_id = payload,
    risk: (state, payload) => state.asset.risk_id = payload,
    type: (state, payload) => state.asset.asset_type_id = payload,
    code: (state, payload) => state.asset.code = payload,
    imageUrl: (state, payload) => state.asset.image_url = payload,
    assetType: (state, payload) => state.asset.asset_type_id = payload,
    nextInspection: (state, payload) => state.asset.next_inspection = payload,
    setAssetTypeList: (state, payload) => state.assetTypeList = payload,
    setAssetForInspection: (state, payload) => {
      state.asset = payload.asset
      state.latest_answers = payload.latest_answers
    },
    clear: (state) => {
      state.assets = {
        data: []
      }
      state.asset = new Asset
    },
    clearAsset: (state) => state.asset = new Asset,
    setOverview: (state, payload) => state.overview = payload,
    updateAttribute: (state, payload) => {
      /**
       * Because this is a nested object, and is sometimes null, and with properties being added and removed completely on edit,
       * all properties are not present on VUEX init, so state is not tracked properly. The object is then distributed
       * to multiple children that need to be reactive,
       * so we have to expressly set the properties for Vue to properly track asset.meta_data with Vue.set
       **/
      if (!state.asset.meta_data) {
        Vue.set(state.asset, 'meta_data', {})
      }
      Vue.set(state.asset.meta_data, payload.fieldId, payload.value)
    },
    removeAttribute: (state, payload) => {
      state.asset.meta_data[payload] = ''
      delete state.asset.meta_data[payload]
    },
    detachFromParent: (state) => state.asset.asset_id = null,
    assignToParent: (state, payload) => state.asset.asset_id = payload.parent_id,
    setAssetRegisterAssets: (state, payload) => state.assetRegisterAssets = payload,
  },

  actions: {
    loadInspectionHistory: ({commit}, payload) => new Promise((resolve, reject) => {
      commonBackend.loadPath(payload, ({data}) => {
        commit('setInspectionHistory', data)
        resolve()
      }, error => reject(error))
    }),
    loadAssetRegisterAssets: ({commit}, payload) => new Promise((resolve, reject) => {
      backend.loadAssetRegisterAssets(payload, ({data}) => {
        commit('setAssetRegisterAssets', data)
        resolve()
      }, error => reject(error))
    }),
    loadAssetHistory: ({commit}, payload) => new Promise((resolve, reject) => {
      commonBackend.loadPath(payload, ({data}) => {
        commit('setAssetHistory', data)
        resolve()
      }, error => reject(error))
    }),
    loadSensors: ({commit}, assetId) => new Promise((resolve, reject) => {
      backend.loadSensors(assetId, ({data}) => {
        commit('setSensors', data)
        resolve(data)
      }, error => reject(error))
    }),
    loadOverview: ({commit}, assetId) => new Promise((resolve, reject) => {
      backend.loadOverview(assetId, ({data}) => {
        commit('setOverview', data)
        resolve(data)
      }, error => reject(error))
    }),
    loadAssetsByZone: ({commit}, path) => new Promise((resolve, reject) => {
      commonBackend.loadPath(path, ({data}) => {
        commit('setAssets', data)
        resolve(data)
      }, error => reject(error))
    }),
    loadAssetsBySite: ({commit}, path) => new Promise((resolve, reject) => {
      commonBackend.loadPath(path, ({data}) => {
        commit('setAssets', data)
        resolve(data)
      }, error => reject(error))
    }),
    loadAsset: ({commit}, assetId) => new Promise((resolve, reject) => {
      backend.loadAsset(assetId, ({data}) => {
        commit('setAsset', data)
        resolve(data)
      }, error => reject(error))
    }),
    loadAssetForAuditInfo: ({}, assetId) => new Promise((resolve, reject) => {
      backend.loadAsset(assetId, ({data}) => {
        resolve(data)
      }, error => reject(error))
    }),
    loadAssetTypeList: ({commit}, assetId) => new Promise((resolve, reject) => {
      backend.loadAssetTypeList(({data}) => {
        commit('setAssetTypeList', data)
        resolve(data)
      }, error => reject(error))
    }),
    loadSubAssets: ({commit}, assetId) => new Promise((resolve, reject) => {
      backend.loadSubAssets(assetId, ({data}) => {
        commit('setSubAssets', data)
        commit('setSelectedSubAssets', data)
        resolve(data)
      }, error => reject(error))
    }),
    save: ({state}) => new Promise((resolve, reject) => {
      backend.save(state.asset, ({data}) => {
        resolve(data)
      }, error => reject(error))
    }),
    loadForInspection: ({commit}, assetId) => new Promise((resolve, reject) => {
      backend.loadForInspection(assetId, ({data}) => {
        commit('setAssetForInspection', data)
        resolve(data)
      }, error => reject(error))
    }),
    loadLastCheckin: ({}, assetId) => new Promise((resolve, reject) => {
      backend.loadLastCheckin(assetId, ({data}) => {
        resolve(data)
      }, error => reject(error))
    }),
    transfer: ({state}, payload) => new Promise((resolve, reject) => {
      backend.transfer({
        asset: state.asset.id,
        to_zone: payload
      }, ({data}) => {
        resolve(data)
      }, error => reject(error))
    }),
    assignToAsset: ({commit}, payload) => new Promise((resolve, reject) => {
      backend.assignToAsset({
        ...payload
      }, ({ data }) => {
        commit('assignToParent', payload)
        resolve(data)
      }, error => reject(error))
    }),
    unassignAsset: ({commit}, payload) => new Promise((resolve, reject) => {
      backend.unassignAsset({
        ...payload
      }, ({ data }) => {
        commit('detachFromParent')
        resolve(data)
      }, error => reject(error))
    }),
    decommission: ({state}, reason) => new Promise((resolve, reject) => {
      backend.decommission({
        asset: state.asset.id,
        reason,
        // sub_assets_handle_method
      }, ({data}) => {
        resolve(data)
      }, error => reject(error))
    }),
    recoverDecommissionedAsset: ({}, assetId) => new Promise((resolve, reject) => {
      backend.recoverDecommission(assetId, ({data}) => {
        resolve(data)
      }, error => reject(error))
    }),
    loadTransactionHistory: ({commit}, payload) => new Promise((resolve, reject) => {
      commonBackend.loadPath(
          payload,
          ({data}) => {
        commit('setTransactionHistory', data)
        resolve(data)
      }, error => reject(error))
    }),
    loadAvailableSubAssets: ({commit}, payload) => new Promise((resolve, reject) => {
      backend.loadAvailableSubAssets(payload, ({data}) => {
        commit('setAvailableSubAssets', data)
        resolve(data)
      }, error => reject(error))
    }),
    syncSubAssets: ({commit}, payload) => new Promise((resolve, reject) => {
      backend.syncSubAssets(payload, ({data}) => {
        commit('setSelectedSubAssets', data)
        commit('setSubAssets', data)
        resolve(data)
      }, error => reject(error))
    }),
    uploadImage: ({commit}, payload) => new Promise((resolve, reject) => {
      backend.uploadImage(payload, ({data}) => {
        commit('imageUrl', data)
        resolve(data)
      }, error => reject(error))
    }),
    clearImage: ({commit}, assetId) => new Promise((resolve, reject) => {
      backend.clearImage(assetId, ({data}) => {
        commit('imageUrl', null)
        resolve(data)
      }, error => reject(error))
    }),
  },

  getters: {
    assets: state => state.assets,
    overview: state => state.overview,
    assetTypeList: state => state.assetTypeList,
    asset: state => state.asset,
    inspectionHistory: state => state.inspectionHistory,
    assetHistory: state => state.assetHistory,
    transactionHistory: state => state.transactionHistory,
    latest_answers: state => state.latest_answers,
    sensors: state => state.sensors,
    availableSubAssets: state => state.availableSubAssets,
    selectedSubAssets: state => state.selectedSubAssets,
    assetRegisterAssets: state => state.assetRegisterAssets,
  }
}

export default asset