import { common as commonBackend, job as backend } from '@/api'
import Job from '@/models/Job'
import { pick } from 'lodash'

let job = {

  namespaced: true,

  state: {
    jobs: {
      data: [],
      meta: {}
    },
    overview: {
      job: new Job(),
      counts: {
        duration: {},
        inspections: {},
        risk_spread: {},
        zones: {},
        job: new Job
      }
    },
    jobSchedule: {},
    jobCalendar: {},
    job: new Job()
  },

  mutations: {
    setJobs: (state, payload) => state.jobs = payload,
    setJobSchedule: (state, payload) => state.jobSchedule = payload,
    setJobCalendar: (state, payload) => state.jobCalendar = payload,
    setJob: (state, payload) => state.job = payload,
    setAnswers: (state, payload) => state.job.answers = payload,
    reference: (state, payload) => state.job.reference = payload,
    technician: (state, payload) => state.job.technician_id = payload,
    startDate: (state, payload) => state.job.start_date = payload,
    startTime: (state, payload) => state.job.start_time = payload,
    endDate: (state, payload) => state.job.end_date = payload,
    endTime: (state, payload) => state.job.end_time = payload,
    setFrequency: (state, payload) => state.job.frequency = payload,
    allowEarlyClosure: (state, payload) => state.job.allow_early_closure = payload,
    addZone: (state, payload) => state.job.zones.push(payload),
    setOverview: (state, payload) => state.overview = payload,
    specialInstructions: (state, payload) => state.job.special_instructions = payload,
    signatureName: (state, payload) => state.job.signature_name = payload,
    site: (state, payload) => {
      state.job.site_id = payload
      state.job.zones = []
    },
    jobType: (state, payload) => state.job.job_type_id = payload,
    updateZones: (state, payload) => state.job.zones = payload,
    clear(state) {
      state.jobs = {
        data: []
      }
      state.job = new Job
    },
    clearJob(state) {
      state.job = new Job
    },
    toggleSignatureRequirement: (state) => state.job.requires_signature = !state.job.requires_signature,
    jobTypeRequiresSignature: (state) => state.job.requires_signature = true,
    setClosingCustomFieldAnswer: (state, payload) => {
      if(!state.job.answers) {
        state.job.answers = {}
      }
      state.job.answers[payload.label] = payload.value
    },
  },

  actions: {
    loadJobs: ({ commit }, path) => new Promise((resolve, reject) => {
      commonBackend.loadPath(path, response => {
        commit('setJobs', response.data)
        resolve(response.data)
      }, reject)
    }),
    loadDeletedJobs: ({ commit }, path) => new Promise((resolve, reject) => {
      commonBackend.loadPath(path, response => {
        commit('setJobs', response.data)
        resolve(response.data)
      }, reject)
    }),
    loadGroupedSchedule: ({ commit }) => new Promise((resolve, reject) => {
      backend.loadGroupedSchedule(response => {
        commit('setJobSchedule', response.data)
        resolve(response.data)
      }, reject)
    }),
    loadJobCalendar: ({ commit }) => new Promise((resolve, reject) => {
      backend.loadJobCalendar(response => {
        commit('setJobCalendar', response.data)
        resolve(response.data)
      }, reject)
    }),
    loadUpcomingJobsByTech: ({ commit }, path) => new Promise((resolve, reject) => {
      backend.loadJobs(path, response => {
        commit('setJobs', response.data)
        resolve(response.data)
      }, reject)
    }),
    loadJobManager: ({ commit }, path) => new Promise((resolve, reject) => {
      commonBackend.loadPath(path, ({ data }) => {
        commit('setJob', data.job)
        commit('setAnswers', data.answer)
        resolve({ inspections: data.inspections, documents: data.documents, job_approval_requests: data.job_approval_requests })
      }, reject)
    }),
    store: ({ state }) => new Promise((resolve, reject) => {
      backend.store(state.job, response => resolve(response.data), error => reject(error))
    }),
    save: ({ state }) => new Promise((resolve, reject) => {
      let data = pick(state.job, [
        'id',
        'job_type_id',
        'technician_id',
        'start_date',
        'start_time',
        'end_date',
        'end_time',
        'allow_early_closure',
        'zones',
        'special_instructions',
        'reference',
        'site_id'
      ])
      backend.save({
        ...data,
        zones: state.job.zones.map(zone => zone.id)
      }, ({ data }) => {
        resolve(data)
      }, reject)
    }),
    addZone: ({ state, commit }, zone) => new Promise((resolve, reject) => {
      backend.addZone({
        zone,
        job: state.job.id
      }, ({ data }) => {
        commit('addZone', data)
        resolve(data)
      }, reject)
    }),
    saveClosingComments: ({}, payload) => new Promise((resolve, reject) => {
      backend.storeClosingCommentsForJob(payload, response => {resolve(response.data)}, reject)
    }),
    closeJob: ({}, jobId) => new Promise((resolve, reject) => {
      backend.closeJob(jobId, response => {
        resolve(response.data)
      }, reject)
    }),
    signJob:  ({}, jobData) => new Promise((resolve, reject) => {
      backend.signJob(jobData, response => {
        resolve(response.data)
      }, reject)
    }),
    reopenJob: ({}, jobId) => new Promise((resolve, reject) => {
      backend.reopenJob(jobId, response => {
        resolve(response.data)
      }, reject)
    }),
    deleteJob: ({}, jobId) => new Promise((resolve, reject) => {
      backend.deleteJob(
        jobId,
        () => resolve(),
        reject
      )
    }),
    loadOverview: ({ commit }, jobId) => new Promise((resolve, reject) => {
      backend.loadOverview(jobId, response => {
        commit('setOverview', response.data)
        resolve(response.data)
      }, reject)
    }),
    recoverJob: ({}, jobId) => new Promise((resolve, reject) => {
      backend.recoverJob(jobId, response => {
        resolve(response.data)
      }, reject)
    }),
    fetchAssetTypesByJob: (context, jobId) => new Promise((resolve, reject) => {
      backend.getAssetTypesByJob(jobId, response => {
        resolve(response.data)
      }, reject)
    }),
  },

  getters: {
    jobs: state => state.jobs,
    jobSchedule: state => state.jobSchedule,
    job: state => state.job,
    overview: state => state.overview,
  }

}

export default job
