import AssetType from '@/models/AssetType'
import Paginator from '@/models/Paginator'
import { assetType as backend, common as commonBackend } from '@/api'
import { findIndex, map } from 'lodash'

let assetType = {

  namespaced: true,

  state: {
    assetTypes: new Paginator,
    assetTypeList: [],
    assetType: new AssetType,
    customFieldId: null,
    overview: {
      assets: 0,
      defective_assets: 0,
      ok_assets: 0,
    },
  },

  mutations: {
    setAssetTypes: (state, payload) => state.assetTypes = payload,
    setAssetTypeList: (state, payload) => state.assetTypeList = payload,
    setAssetType: (state, payload) => {
      state.assetType = payload
      state.assetType.custom_fields = JSON.parse(state.assetType.custom_fields) || []
    },
    deLinkFromInvalidParent: (state, fieldId) => {
      let index = findIndex(state.assetType.custom_fields, customField => customField.field_id === fieldId)
      let field = state.assetType.custom_fields[index]
      if (field) {
        field.linked_field = null
        field.options?.forEach(option => {
          option.linked_value = null
        });
      }
      state.assetType.custom_fields.splice(index, 1, field)
    },
    addType: (state, payload) => state.assetTypes.data.push(payload),
    name: (state, payload) => state.assetType.name = payload,
    task: (state, payload) => state.assetType.task = payload,
    setChecklists: (state, payload) => state.assetType.checklists = payload,
    risk: (state, payload) => state.assetType.risk_id = payload,
    code: (state, payload) => state.assetType.short_code = payload,
    image: (state, payload) => state.assetType.image = payload,
    imageUrl: (state, payload) => state.assetType.image_url = payload,
    enforceChecklistOrder: (state, payload) => state.assetType.enforce_checklist_order = payload,
    allowBarcodelessInspections: (state, payload) => state.assetType.allow_barcodeless_inspections = payload,
    clearType: (state) => state.assetType = new AssetType,
    addCustomField: (state, field) => state.assetType.custom_fields.push(field),
    addDefaultCustomFieldOption: (state, field) => {
      const index = findIndex(state.assetType.custom_fields, customField => customField.field_id === field.field_id)
      let customField = state.assetType.custom_fields[index]
      customField.options.push({value: field.default, default: true})
    },
    updateCustomField: (state, field) => {
      let customFields = state.assetType.custom_fields
      let index = findIndex(customFields, customField => customField.field_id === field.field_id)
      let existingField = customFields[index]
      existingField.label = field.label
      existingField.is_required = field.is_required
      existingField.linked_field = field.linked_field
      existingField.placeholder = field.placeholder
      state.assetType.custom_fields = customFields
    },
    updateField: (state, { fieldId, property, value }) => {
      let index = findIndex(state.assetType.custom_fields, customField => customField.field_id === fieldId)
      let field = state.assetType.custom_fields[index]
      if (field) {
        field[property] = value;
      }
      state.assetType.custom_fields.splice(index, 1, field)
    },
    deleteField: (state, field) => {
      let index = findIndex(state.assetType.custom_fields, customField => customField.field_id === field.field_id)
      state.assetType.custom_fields.splice(index, 1)
    },
    setCustomField: (state, field) => {
      state.assetType.selected_custom_field = field
    },
    setPredefinedValue: (state, payload) => {
      let index = findIndex(state.assetType.custom_fields, field => field.field_id === payload.id)
      let field = state.assetType.custom_fields[index]
      field.options = payload.options
      let defaultValue =field.options.find(option => option.default === true)
      field.default = defaultValue?.value ?? null
      state.assetType.custom_fields.splice(index, 1, field)
    },
    setLinkedField: (state, payload) => {
      let field = state.assetType.custom_fields.find(field => field.field_id === payload.id)
      field.linked_field = payload.linked_field
    },
    setOverview: (state, payload) => state.overview = payload,
    updateAssetGroups: (state, payload) => state.assetType.zone_types = payload,
    updateFieldLabel: (state, payload) => {
      let index = findIndex(state.assetType.custom_fields, field => field.field_id === payload.fieldId)
      let field = state.assetType.custom_fields[index]
      field.label = payload.value
      state.assetType.custom_fields.splice(index, 1, field)
    },
    updateFieldPlaceholder: (state, payload) => {
      let index = findIndex(state.assetType.custom_fields, field => field.field_id === payload.fieldId)
      let field = state.assetType.custom_fields[index]
      field.placeholder = payload.value
      state.assetType.custom_fields.splice(index, 1, field)
    },
    updateFieldDefault: (state, payload) => {
      let index = findIndex(state.assetType.custom_fields, field => field.field_id === payload.fieldId)
      let field = state.assetType.custom_fields[index]
      field.default = payload.value
      state.assetType.custom_fields.splice(index, 1, field)
    },
    toggleRequiredField: (state, fieldId) => {
      let index = findIndex(state.assetType.custom_fields, field => field.field_id === fieldId)
      let field = state.assetType.custom_fields[index]
      field.is_required = !field.is_required
      state.assetType.custom_fields.splice(index, 1, field)
    },
    addStatus: (state, payload) => {
      state.assetType.dynamicStatuses.push(payload)
    },
    removeStatus: (state, index) => {
      state.assetType.dynamicStatuses.splice(index, 1)
    }
  },

  actions: {
    loadAssetType: ({ commit }, assetType) => new Promise((resolve, reject) => {
      backend.loadAssetType(assetType, response => {
        commit('setAssetType', response.data)
        resolve()
      }, reject)
    }),
    loadAssetTypes: ({ commit }, path) => new Promise((resolve, reject) => {
      commonBackend.loadPath(path, response => {
        commit('setAssetTypes', response.data)
        resolve()
      }, error => reject(error))
    }),
    requestAssetHistoryReport: ({ state }) => new Promise((resolve, reject) => {
      backend.requestAssetHistoryReport(state.assetType.id, response => {
        resolve(response)
      }, error => reject(error))
    }),
    searchAssetTypes: ({ commit }, payload) => new Promise((resolve, reject) => {
      backend.searchAssetTypes(payload, response => {
        commit('setAssetTypeList', response.data)
        resolve()
      }, error => reject(error))
    }),
    loadAssetTypeList: ({ commit }) => new Promise((resolve, reject) => {
      backend.loadAssetTypeList(response => {
        commit('setAssetTypeList', response.data)
        resolve()
      }, error => reject(error))
    }),
    store: ({ state, commit }) => new Promise((resolve, reject) => {
      let payload = { ...state.assetType }
      payload.checklists = map(payload.checklists, 'id')
      payload.zone_types = map(payload.zone_types, 'id')
      backend.store(payload, response => {
        commit('addType', response.data)
        resolve(response.data)
      }, reject)
    }),
    save: ({ state }) => new Promise((resolve, reject) => {
      let payload = { ...state.assetType }
      payload.checklists = map(payload.checklists, 'id')
      payload.zone_types = map(payload.zone_types, 'id')
      backend.saveAssetType(
        payload,
        response => resolve(response.data),
        reject,
      )
    }),
    delete: ({ state }) => new Promise((resolve, reject) => {
      backend.delete(
        state.assetType.id,
        response => resolve(response.data),
        error => reject(error),
      )
    }),
    loadOverview: ({ state, commit }) => new Promise((resolve, reject) => {
      backend.loadOverview(
        state.assetType.id,
        response => {
          commit('setOverview', response.data)
          resolve()
        },
        error => reject(error),
      )
    }),
    importAssets: ({ state }, payload) => new Promise((resolve, reject) => {
      backend.importAssets(
        payload,
        response => resolve(response.data),
        error => reject(error),
      )
    }),
  },

  getters: {
    assetTypes: state => state.assetTypes,
    assetTypeList: state => state.assetTypeList,
    assetType: state => state.assetType,
    overview: state => state.overview,
  },

}

export default assetType
