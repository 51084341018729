import Checklist from "@/models/Checklist";
import {company as backend} from "@/api";
export default {
    namespaced: true,

    state: {
        companyId: null,
        title: "Asset Register Report",
        preamble: "",
        postamble: "",
        documentNumber: "",
        assetTypes: [],
        checklistIds: [],
        dataMap: [
            {
                column_index: 0,
                label: "Asset Type",
                field_type: "asset",
                field_value: "type.name",
            },
            {
                column_index: 1,
                label: "Code",
                field_type: "asset",
                field_value: "code",
            },
            {
                column_index: 2,
                label: "Zone",
                field_type: "asset",
                field_value: "zone",
            },
            {
                column_index: 3,
                label: "Risk Level",
                field_type: "asset",
                field_value: "risk",
            },
            {
                column_index: 4,
                label: "Status",
                field_type: "asset",
                field_value: "is_defective",
            },
        ],
        jobList: [],
        jobId: null,
    },

    getters: {
        companyId: state => state.companyId,
        title: state => state.title,
        preamble: state => state.preamble,
        postamble: state => state.postamble,
        documentNumber: state => state.documentNumber,
        assetTypes: state => state.assetTypes ?? [],
        checklistIds: state => state.checklistIds ?? [],
        dataMap: state => state.dataMap,
        jobList: state => state.jobList,
        jobId: state => state.jobId,
    },

    mutations: {
        setCompanyId: (state, payload) => state.companyId = payload,
        setTitle: (state, payload) => state.title = payload,
        setDocumentNumber: (state, payload) => state.documentNumber = payload,
        setPreamble: (state, payload) => state.preamble = payload,
        setPostamble: (state, payload) => state.postamble = payload,
        setAssetTypes: (state, payload) => state.assetTypes = payload,
        refreshCustomFields:  (state, payload) => {
            state.dataMap = state.dataMap.filter(item => {
                if (item.field_type === 'customField') {
                    return payload.some(customField => customField.field_value === item.field_value);
                }
                return true;
            });
        },
        setChecklistIds: (state, payload) => state.checklistIds = payload,
        clearChecklistIds: (state) => state.checklistIds = [],
        setDataMap: (state, payload) => state.dataMap = payload,
        setCompanyAssetRegisterConfiguration: (state, payload) => {
            state.companyId = payload.company_id
            state.title = payload.title
            state.preamble = payload.preamble
            state.postamble = payload.postamble
            state.assetTypes = payload.asset_type_ids
            state.checklistIds = payload.checklist_ids
            state.dataMap = payload.data_map
        },
        setJobsForThisReport: (state, payload) => state.jobList = payload,
        setJobId: (state, payload) => state.jobId = payload,
        clearJobId: (state) => state.jobId = null,
        clearDocumentNumber: (state) => state.documentNumber = ''
    },

    actions: {
        fetchCompanyAssetRegisterConfiguration: ({commit}) => new Promise((resolve, reject) => {
            backend.fetchCompanyAssetRegisterConfiguration(response => {
                commit('setCompanyAssetRegisterConfiguration', response.data)
                resolve()
            }, reject)
        }),
        store: ({commit, state}) => new Promise((resolve, reject) => {
            backend.storeAssetReport(state, response => {
                commit('setCompanyAssetRegisterConfiguration', response.data)
                resolve()
            }, reject)
        }),
        storeFilters: ({}, payload) => new Promise((resolve, reject) => {
            backend.storeFilters(payload, response => {
                resolve()
            }, reject)
        }),
        exportReportToPdf: ({state}, site) => new Promise((resolve, reject) => {
            backend.exportAssetReportToPdf({state, site}, response => {
                resolve()
            }, reject())
            }
        ),
        exportReportToExcel: ({state}, site) => new Promise((resolve, reject) => {
                backend.exportAssetReportToExcel({state, site}, response => {
                    resolve()
                }, reject())
            }
        ),
        loadJobs: ({state, commit}, site) => new Promise((resolve, reject) => {
            backend.loadJobs({state, site}, response => {
                commit('setJobsForThisReport', response.data)
                resolve()
            }, reject())
        })
    },
}